import { ChevronLeft, ChevronRight } from "react-feather";
import { useState, useEffect } from "react";
import axios from "axios";

export default function Carousel() {
    const [data, setData] = useState(null);
    const token = 'Aa5PbtwL9kXHL5n0/pwQMvVo1BhqPj7G0tROQO6Pa4g=';
    const url = `https://admin.litahusin.com/api/AllNews?token=${token}`;
    const [popupOpenIndex, setPopupOpenIndex] = useState(-1);
    const [activeSlide, setActiveSlide] = useState(0); // Menambahkan deklarasi activeSlide

    const getData = async () => {
        const { data } = await axios.get(url);
        const news = data.value_response.slice();
        setData(news);
    };
    
    useEffect(() => {
        getData();
    }, []);

    const prev = () => {
        setPopupOpenIndex(-1);
        setActiveSlide((curr) => (curr === 0 ? data.length - 1 : curr - 1));
    };

    const next = () => {
        setPopupOpenIndex(-1);
        setActiveSlide((curr) => (curr === data.length - 1 ? 0 : curr + 1));
    };
    console.log("popupOpenIndex:", popupOpenIndex);

    return (
        <>
        <div className="layout-slide">
            <div className="slide-box">
                {data &&
                    data.map((news, indeks) => (
                        <div key={indeks} className={`slide-box-img ${activeSlide === indeks ? 'active' : ''}`}>
                            <img src={news.media} alt={`Slide ${indeks}`} />
                            <h3>{news.title}</h3>
                            <button onClick={() => setPopupOpenIndex(indeks)}>Buka Popup</button>
                            {popupOpenIndex === indeks && (
                                <div className="popup">
                                    <button onClick={() => setPopupOpenIndex(-1)}>Tutup Popup</button>
                                    <h3>{news.title}</h3>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
            <div className="navigation-slide">  
                <button onClick={prev}>
                    <ChevronLeft size={40}/>
                </button>
                <button onClick={next}>
                    <ChevronRight size={40}/>
                </button>
            </div>
            
        </div>
        <style jsx>{`
            .slide-box {
                display: flex;
                transition: transform 0.3s ease-in-out;
                transform: translateX(-${activeSlide * 100}%);
            }
            .slide-box-img {
                flex: 0 0 100%;
                text-align: center;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0.5; /* Mengurangi opasitas untuk slide non-aktif */
                position: relative;
            }
            .slide-box-img.active {
                opacity: 1; /* Memberikan opasitas penuh pada slide aktif */
            }
            .slide-box-img img {
                max-width: 70%;
                max-height: 70%;
                display: block;
                margin: auto;
            }
            .popup {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: green;
            }         
        `}</style>
        </>
    );
}
